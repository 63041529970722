export const Calender = require("../assets/calender.png");
export const ProfileIcon = require("../assets/user_icon.png");
export const filterIcons = require("../assets/filter_new.png");
export const filterIcon = require('../assets/setting-4.png');
export const searchIcons = require("../assets/searchIcon.png");
export const noData = require('../assets/closeBtn.png');
export const prescriptionIcon = require('../assets/prescription.png');
export const DownArrow = require('../assets/down-arrow.png');
export const UpwardArrow = require('../assets/upward-arrow.png');
export const AddBtn = require('../assets/add.png');
export const SubtractBtn = require('../assets/subtract.png');
export const Signature = require('../assets/Signature.png');
export const HomecureLogo = require('../assets/HomecureLogo.png');
export const Star = require('../assets/Star.png')