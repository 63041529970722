import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");

export enum FilterMode {
    INCOMING = 'incoming',
    UPCOMING = 'upcoming',
    PAST = 'past',
    NONE = 'none',
}

export enum ManageView {
    BED = 'bed',
    HEALTHCARE_PERSON = 'healthcare-person',
}

export enum ModalType {
    PATIENT_DETAILS = 'patient-details',
    HEALTH_DETAILS = 'health-details',
    INSURANCE_MODAL = 'insurance-modal',
}

export interface Props {
    navigation: any;
    id: string;
    classes: any;
}

interface S {
    manageView: ManageView;
    modalType: ModalType
    modalOpen: boolean;
    selectedDate: Date;
    filterPopoverEl: HTMLButtonElement | null;
    filterMode: FilterMode;
    selectedFilter: FilterMode;
    modalOpenFrom: "link" | "card",
    date: Date;
    isOpen: boolean;
    showPopup: boolean;
    managementData: any;
    loading: boolean;
    selectedFilters: string;
    currentPage: number;
    hospitalList:any;
    hpList:any;
    hpStatus: boolean;
    showAllData:boolean;
    isDataEmpty: boolean;
    totalCount: number;
    prevScrollTop: number;
    rating:number;
}

interface SS {
    id: number;
}

export default class OrderManagementController extends BlockComponent<
    Props,
    S,
    SS
> {
    scrollBookingContainerRef: React.RefObject<HTMLDivElement> = React.createRef();
    getPatientOrderCallId: string = "";
    getHospialOrderCallId: string = "";
    getHPOrderCallId: string = "";
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
          getName(MessageEnum.AccoutLoginSuccess),
          getName(MessageEnum.RestAPIResponceMessage),
      ];

        this.state = {
            manageView: ManageView.BED,
            modalType: ModalType.PATIENT_DETAILS,
            modalOpen: false,
            selectedDate: new Date(),
            filterPopoverEl: null,
            filterMode: FilterMode.INCOMING,
            selectedFilter: FilterMode.INCOMING,
            modalOpenFrom: 'card',
            date: new Date(),
            isOpen:false,
            showPopup:false,
            managementData: [],
            loading: true,
            selectedFilters: "upcoming",
            currentPage: 1,
            hospitalList:[],
            hpList:[],
            hpStatus: false,
            showAllData: false,
            isDataEmpty: false,
            totalCount:0,
            prevScrollTop: 0,
            rating:0
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
      runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            this.handleOrderManagementResponse(message);
        }
      }

      handleOrderManagementResponse = (message: Message) => {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        switch (apiRequestCallId) {
            case this.getPatientOrderCallId:
                this.getPatientOrderResponse(responseJson)
                break;
            case this.getHospialOrderCallId:
                this.getHospitalProfileResponse(responseJson)
                break;    
            case this.getHPOrderCallId:
                this.getHPResponse(responseJson)
                break;       
        }
    }

    onHandelScroll = () => {
      const element = this.scrollBookingContainerRef.current;
      if (element && !this.state.loading) {
          const { scrollTop, scrollHeight, clientHeight } = element
          const isContentRemaining = this.state.managementData?.length < this.state.totalCount
          if (scrollTop > this.state.prevScrollTop && scrollTop + clientHeight >= scrollHeight - 50 && isContentRemaining) {
              this.setState(prevState => ({currentPage: Math.max(1, prevState.currentPage + 1)}));
          }
          this.setState({ prevScrollTop: scrollTop });
      }
  };

  async componentDidUpdate(prevProps: Props, prevState: any) {
    const { currentPage } = this.state;
    if (prevState.currentPage !== currentPage) {
      try {
        if (!this.state.isDataEmpty && !this.state.loading) {
          await this.fetchUserProfileDatas(); 
        }
      } catch (error) {
        this.showAlert("Error fetching booking data:", "Something went wrong");
      }
    }
  }


    getPatientOrderResponse = (responseJson: any) => {
        if (responseJson?.reservations) {
          this.setState((prevState:any) => {
            return {
                isDataEmpty: responseJson?.reservations?.data.length === 0,
                managementData: [...prevState.managementData, ...responseJson?.reservations?.data],
                totalCount: responseJson.meta.total_count,
                loading: false
            }
        })
        } else {
          this.setState({loading: false})
        }
    };

    getHospitalProfileResponse = (responseJson: any) => {
      if (responseJson?.hospital_booking) {
        this.setState({hospitalList:responseJson?.hospital_booking?.data.attributes})
      }
    };
  
    getHPResponse = async(responseJson: any) => {
      const profileType = await getStorageData("profileType") 
      if((profileType === "nurse") || (profileType === "ayah")) {
        if (responseJson?.caretaker_reservation) {
          this.setState({hpList:responseJson?.caretaker_reservation?.data.attributes,hpStatus:false})
        }
      }else{
        if (responseJson?.medic_reservation) {
          this.setState({hpList:responseJson?.medic_reservation?.data.attributes,hpStatus:true})
        }
      }
    };

    formatCustomDate = (date: any) => {
        if (!date || !(date instanceof Date)) {
            return '';
        } else {
            const formattedDate = new Date(date).toLocaleDateString('en-GB', {
                weekday: 'long',
                day: 'numeric',
                month: 'long',
                year: 'numeric',
            });

            return formattedDate;
        }
    }

    handleDateChange = (date: Date) => {
        this.setState({ selectedDate: date })
    }


    handleOpenBedView = () => {
        this.setState({ manageView: ManageView.BED })
    }

    handleOpenHealthCarePersonView = () => {
        this.setState({ manageView: ManageView.HEALTHCARE_PERSON })
    }


    handleCustomDateChange = (customDate: any) => {
        this.setState({ date: customDate, isOpen: false,managementData:[] },() =>
          this.fetchUserProfileDatas()
        );
    }
  
    toggleDatePicker = () => {
      this.setState({isOpen:!this.state.isOpen})
    }
  
    checkProfileType(profileType: string) {
      setStorageData('profileType',profileType)
      switch (profileType) {
        case "nurse":
          return true
        case "doctor":
            return true
        case "ayah":
            return true    
        case "physiotherapist":
            return true 
        case "dietician":
            return true            
        default:
          return false;
      }
    }

  handleRating = (value: number) => {
    this.setState({ rating: value })
  }

    openProfile = (profileType:string,id:number) => {
      const page = this.checkProfileType(profileType) === true ? "HPUserProfile" : "UserProfile";
      const navigateToProfile = new Message(getName(MessageEnum.NavigationMessage));
      navigateToProfile.addData(getName(MessageEnum.NavigationTargetMessage), page);
      navigateToProfile.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      navigateToProfile.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
      this.send(navigateToProfile);
    }
  
    backToOrderManagementScreen = () => {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "PatientOrderManagement");
      message.addData(
          getName(MessageEnum.NavigationPropsMessage),
          this.props
      );
      this.send(message);
    }
  
    togglePopup = () => {
      this.setState({showPopup: !this.state.showPopup})
    }

    formatDateToDDMMYYYY = (date: Date, type:string): string => {
      const day: string = String(date.getDate()).padStart(2, '0');
      const month: string = String(date.getMonth() + 1).padStart(2, '0');
      const year: number = date.getFullYear();
    
      return `${day}${type}${month}${type}${year}`;
    }

    fetchUserProfileDatas = async () => {
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      const date= new Date(this.state.date);
      const formattedDate: string = this.formatDateToDDMMYYYY(date,"-");

      this.getPatientOrderCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getPatient}?page=${this.state.currentPage}&per_page=10&upcoming_reservation_date=${formattedDate}&filter=${this.state.selectedFilters}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.listOfOrdersMethod
      );
  
      const token = await getStorageData("authToken");
  
      const header = {
        "Content-Type": "application/json",
        token: token,
      };
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    };

    newFormatDate = (inputDate:any) => {
      const date =  new Date(inputDate)
      if (!(date instanceof Date)) {
        throw new Error('Input must be a Date object');
      }
    
      return date.toLocaleDateString('en-US', {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      });
    } 

    onChangeFilter = (name:string) => {
      this.setState({selectedFilters:name,showPopup:false,managementData:[]},()=>{
        this.fetchUserProfileDatas()
      })
    }

    fetchHospitalData = async () => {
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.getHospialOrderCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getHospital}${this.props.navigation.getParam("navigationBarTitleText")}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.listOfOrdersMethod
      );
  
      const token = await getStorageData("authToken");
  
      const header = {
        "Content-Type": "application/json",
        token: token,
      };
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    };
       
    checkIfProfileIsDoctor = async() => {
      const profileType = await getStorageData("profileType") 

      if(profileType === "doctor") {
        return "DR. "
      }else{ 
        return ""
      }
    }

    fetchHPData = async () => {
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.getHPOrderCallId = requestMessage.messageId;

      const profileType = await getStorageData("profileType") 

      let url = configJSON.getMedicsReservation;

      if((profileType === "nurse") || (profileType === "ayah")) {
        url = configJSON.getCaretakerReservation;
      }
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${url}${this.props.navigation.getParam("navigationBarTitleText")}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.listOfOrdersMethod
      );
  
      const token = await getStorageData("authToken");
  
      const header = {
        "Content-Type": "application/json",
        token: token,
      };
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    };


    handelShowAllData = () => {
      if(this.state.managementData.length> 0) {
        this.setState({showAllData: !this.state.showAllData})
      }
    }

  moveToPrescriptionScreen = (id:number) => {
    if (this.state.hpStatus) {
      setStorageData('userNavigationId',id)
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "HealthcareViewPersonnelPrescription");
      message.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      this.send(message);
    }
  }

  goToBlock = (blockName: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), blockName);
    message.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
    );
    this.send(message);
  }

  moveHospitalToPrescriptionScreen = (userId:number) => {
      setStorageData('userNavigationId',userId)
      const messages = new Message(getName(MessageEnum.NavigationMessage));
      messages.addData(getName(MessageEnum.NavigationTargetMessage),"HealthcareViewPersonnelPrescription");
      messages.addData( getName(MessageEnum.NavigationPropsMessage),this.props);
      this.send(messages);
  }

  bookAgain = async(id:number) => {
    const profileType = await getStorageData("profileType") 
    let page = "Reservations2ViewDoctorBooking";
    
    if(profileType==="ayah" || profileType==="nurse"){
      page = "Reservations2ViewNurseBooking"
    }

    const navigateToProfile = new Message(getName(MessageEnum.NavigationMessage));
    navigateToProfile.addData(getName(MessageEnum.NavigationTargetMessage), page);
    navigateToProfile.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    navigateToProfile.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
    this.send(navigateToProfile);
  }
}