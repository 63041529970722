import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Hidden,
  Drawer,
  IconButton,
  Avatar,
  Divider,
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { completed, homecure, offer, rejected, sos } from "./assets";
import Sidebar from "../../../components/src/Sidebar.web";
import CustomStyledButton from "../../../components/src/CustomStyledButton.web";
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import NotificationsRoundedIcon from '@material-ui/icons/NotificationsRounded';

type Notification = {
  type: string;
  mainContent: string;
  time: string;
  name: string | null;
  hasLink: boolean;
};

type NotificationArray = Record<string, Notification[]>;
// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderNotificationIcon = (type: string) => {
    switch (type) {
        case 'sos': return sos;
        case 'completed': return completed;
        case 'rejected': return rejected;
        case 'offer': return offer;
        default: return ''
    }
}

renderNotification = () => {
    const notificationArray :NotificationArray= {
        Today: [
            {
                type: 'sos',
                mainContent: 'ABC Hospital did not accept your emergency booking.',
                time: '9:30 PM',
                name: "Mr. Gauthier Drewitt",
                hasLink: true,
            },
            {
                type: 'completed',
                mainContent: 'Your Booking is confirmed at ABC Hospital with Dr. John',
                time: '9:30 PM',
                name: "Mr. Gauthier Drewitt",
                hasLink: true,
            },
        ],
        Yesterday: [
            {
                type: 'rejected',
                mainContent: 'ABC Hospital is currently not accepting booking',
                time: '9:30 PM',
                name: "Mr. Gauthier Drewitt",
                hasLink: true,
            },
            {
                type: 'offer',
                mainContent: 'You can now check 50% offer on all of the service now!',
                time: '9:30 PM',
                name: null,
                hasLink: false,
            },
        ],
    };

    return (
        <>
            {!this.props.hideNotification && (
                <Box data-test-id='notification-menu' sx={webStyle.notificationWrapper}>
                    <Box sx={webStyle.notification}>
                        <Avatar style={webStyle.avatar} />
                        <Typography style={webStyle.userName}>Selena</Typography>
                        <Typography style={webStyle.userPhone}>+91 9876543210</Typography>
                    </Box>
                    <Box sx={webStyle.notificationSection}>
                        {Object.entries(notificationArray).map(([key, valuesArr]) => (
                            <Box sx={webStyle.notificationCategory} key={key}>
                                <Typography style={webStyle.categoryTitle}>{key}</Typography>
                                {valuesArr.map((value, index) => (
                                    <Box sx={webStyle.notificationItem} key={index}>
                                        <Avatar src={this.renderNotificationIcon(value.type)} />
                                        <Box sx={webStyle.notificationContent}>
                                            <Typography style={webStyle.mainContent}>{value.mainContent}</Typography>
                                            <Box sx={webStyle.footerContent}>
                                                {value.name && (
                                                    <Typography style={webStyle.name}>{value.name}</Typography>
                                                )}
                                                <Typography style={webStyle.time}>{value.time}</Typography>
                                            </Box>
                                            {value.hasLink && (
                                                <Typography style={webStyle.links}>
                                                    <span> Health details </span> | <span> View Insurance </span>
                                                </Typography>
                                            )}
                                            {value.type === 'sos' && (
                                                <Box sx={webStyle.buttonsWrapper}>
                                                    <CustomStyledButton
                                                        style={webStyle.button}
                                                        isSecondary
                                                        label="Decline"
                                                    />
                                                    <CustomStyledButton style={webStyle.button} label="Accept" />
                                                </Box>
                                            )}
                                            <Divider style={webStyle.divider} />
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        ))}
                    </Box>
                </Box>
            )}
        </>
    );
};

renderTopBar = () => {
    return this.state.isMobileScreen && (
        <Box style={webStyle.topBar}>
            <Hidden mdUp implementation="css">
                <IconButton
                    style={webStyle.iconButton}
                    data-test-id="menuBtn"
                    onClick={this.handleOpenDrawer}
                >
                    <MenuRoundedIcon />
                </IconButton>
            </Hidden>
            <Box sx={webStyle.logoBox}>
                <img src={homecure} alt="logo" width="45px" />
                <Typography style={webStyle.typography}>HomeCure</Typography>
            </Box>
            <Hidden mdUp implementation="css">
                {!this.props.hideNotification && (
                    <IconButton
                        style={webStyle.iconButton}
                        data-test-id="notification"
                        onClick={this.handleNotificationDrawer}
                    >
                        <NotificationsRoundedIcon />
                    </IconButton>
                )}
            </Hidden>
        </Box>
    );
}
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={{ width: '100%' }}>
            {this.renderTopBar()}
            <Box sx={webStyle.container}>
                <Box sx={webStyle.sidebarWrapper}>
                    <Hidden smDown implementation="css">
                        <Sidebar data-test-id='side-bar' expandMenu={this.state.isSidebarOpen} toggleSidebar={this.toggleExpandSidebar} />
                    </Hidden>
                    <Hidden xsUp implementation="css">
                        <Drawer
                            style={{ height: '100%' }}
                            open={this.state.openDrawer}
                            onClose={this.handleOpenDrawer}
                            ModalProps={{
                                keepMounted: true,
                            }}>
                            <Sidebar expandMenu={this.state.isSidebarOpen} toggleSidebar={this.toggleExpandSidebar} />
                        </Drawer>
                    </Hidden>
                </Box>
                <Box sx={this.state.contentStyle}>
                    {this.props.children}
                </Box>
                <Box>
                    <Hidden smDown implementation="css">
                        {this.renderNotification()}
                    </Hidden>
                    <Hidden xsUp implementation="css">
                        <Drawer
                            style={{ height: '100%' }}
                            open={this.state.openNotificationDrawer}
                            onClose={this.handleNotificationDrawer}
                            ModalProps={{
                                keepMounted: true,
                            }}>
                            {this.renderNotification()}
                        </Drawer>
                    </Hidden>
                </Box>
            </Box>
        </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  sidebarWrapper: {
      minHeight: 'max(100vh, 700px)'
  },
  notificationWrapper: {
      minWidth: '340px',
      height: '100vh',
      overflowY: 'auto',
      position: 'fixed',
      right: 0,
      border: '1px solid #EFEFEF',
      backgroundColor: 'white',
      scrollbarColor: "#d9d5d5 #fff",
      scrollbarWidth: "thin",
  },
  container: {
      height: "100%",
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      minHeight: "100vh",
      overflowX: "hidden" as const
  },
  topBar: {
      backgroundColor: '#014866',
      padding: '20px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'fixed',
      width: '100%',
      boxSizing: 'border-box',
      zIndex: 1000,
  } as React.CSSProperties,
  iconButton: {
      backgroundColor: '#FFFFFF',
      borderRadius: '5px',
      height: '40px',
      width: '40px',
      color: '#000000',
  },
  logoBox: {
      display: 'flex',
      alignItems: 'center',
      gridColumnGap: '20px',
  },
  typography: {
      fontFamily: 'SF Pro Text Heavy',
      fontSize: '16px',
      color: 'white',
  },
  notification: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderBottom: '1px solid #EFEFEF',
      paddingTop: '46px',
      paddingBottom: '40px',
  },
  avatar: {
      height: '95px',
      width: '95px',
      marginBottom: '15px',
  },
  userName: {
      fontFamily: 'SF Pro Text Semibold',
      fontSize: '20px',
      marginBottom: '10px',
  },
  userPhone: {
      color: '#808080',
      fontFamily: 'SF Pro Text Regular',
      fontSize: '18px',
  },
  notificationSection: {
      padding: '40px 22px 34px 34px',
  },
  notificationCategory: {
      width: '285px',
  },
  categoryTitle: {
      color: '#000000',
      fontFamily: 'SF Pro Text Bold',
      fontSize: '16px',
      lineHeight: '20px',
      marginBottom: '20px',
  },
  notificationItem: {
      display: 'flex',
      gridColumnGap: '17px',
  },
  notificationContent: {
      display: 'flex',
      flexDirection: 'column',
      gridRowGap: '10px',
  },
  mainContent: {
      color: '#808080',
      fontFamily: 'SF Pro Text Regular',
      fontSize: '14px',
      lineHeight: '16px',
  },
  footerContent: {
      display: 'flex',
      justifyContent: 'space-between',
  },
  name: {
      color: '#000000',
      fontFamily: 'SF Pro Text Bold',
      fontSize: '14px',
      lineHeight: '16px',
  },
  time: {
      color: '#B2B2B2',
      fontFamily: 'SF Pro Text Regular',
      fontSize: '14px',
      lineHeight: '16px',
  },
  links: {
      color: '#3780E0',
      fontFamily: 'SF Pro Text Regular',
      fontSize: '14px',
      lineHeight: '16px',
  },
  buttonsWrapper: {
      display: 'flex',
      gridColumnGap: '24px',
      marginTop: '10px',
  },
  button: {
      height: '42px',
      width: '100px',
  },
  divider: {
      height: '1px',
      width: '157px',
      margin: '20px 0',
  }
};
// Customizable Area End
